<template>
  <v-card class="mb-12">
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.LABEL_EMAIL") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ userDeleteRequest.user.email }}
        </v-col>

        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.LABEL_FIRST_NAME") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ userDeleteRequest.user.firstName }}
        </v-col>

        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.LABEL_LAST_NAME") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ userDeleteRequest.user.lastName }}
        </v-col>

        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.LABEL_DELETE_AT") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ userDeleteRequest.deleteAt ? dateTime(userDeleteRequest.deleteAt) : "-" }}
        </v-col>

        <v-col cols="12" md="6" lg="3">
          {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.LABEL_COMMENT") }}
        </v-col>

        <v-col cols="12" md="6" lg="9">
          {{ userDeleteRequest.comment || "-" }}
        </v-col>

        <template v-if="userDeleteRequest.createdBy">
          <v-col cols="12" md="6" lg="3">
            {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.LABEL_CREATED_BY") }}
          </v-col>

          <v-col cols="12" md="6" lg="9">
            {{ userDeleteRequest.createdBy.firstName }} {{ userDeleteRequest.createdBy.lastName }}
          </v-col>
        </template>

        <template v-if="userDeleteRequest.approvedBy">
          <v-col cols="12" md="6" lg="3">
            {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.LABEL_APPROVED_BY") }}
          </v-col>

          <v-col cols="12" md="6" lg="9">
            {{ userDeleteRequest.approvedBy.firstName }} {{ userDeleteRequest.approvedBy.lastName }}
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dateFilter from "@mixins/dateFilter";

export default {
  name: "UserDeleteRequestData",

  mixins: [dateFilter],

  props: {
    userDeleteRequest: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        deleteAt: null,
        user: {
          firstName: null,
          lastName: null,
          email: null,
        },
        createdBy: null,
        approvedBy: null,
      }),
    },
  },
};
</script>
