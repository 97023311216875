<template>
  <ModuleSubPage fullWidth :loading="isLoading" v-if="userDeleteRequest">
    <BaseHasPermission :permissionNames="['user.read']">
      <v-row>
        <v-col>
          <BaseBackButton />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <BaseHeadline size="3">
            {{ $t("USER_MANAGEMENT.USER_DELETE_REQUEST.SHOW.TITLE") }}:
            {{ userDeleteRequest.user.firstName }}
            {{ userDeleteRequest.user.lastName }}
          </BaseHeadline>
        </v-col>
      </v-row>
      <UserDeleteRequestData :userDeleteRequest="userDeleteRequest" />
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import UserDeleteRequestData from "@views/Restricted/UserManagement/UserDeleteRequest/Show/partials/UserDeleteRequestData.vue";
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";

export default {
  name: "ShowUserDeleteRequest",

  components: {
    ModuleSubPage,
    UserDeleteRequestData,
  },

  data() {
    return {
      isLoading: true,
      userDeleteRequestId: this.$route.params.userDeleteRequestId
        ? parseInt(this.$route.params.userDeleteRequestId, 10)
        : null,
      userDeleteRequest: null,
    };
  },

  methods: {
    async showUserDeleteRequest() {
      if (!this.userDeleteRequestId) {
        return;
      }
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.showUserDeleteRequest({
          userDeleteRequestId: this.userDeleteRequestId,
        });
        this.userDeleteRequest = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.showUserDeleteRequest();
  },
};
</script>
